import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import { Box } from 'rebass/styled-components';
import axios from 'axios';

import TextInput from '../TextInput';
import TextArea from '../TextArea';
import CheckboxInput from '../CheckboxInput';
import StyledButton from '../StyledButton';
import validationSchema from './validationSchema';
import initialValues from './initialValues';

const ReviewForm = () => {
  const [status, setStatus] = useState(null);

  return (
    <Box width="100%">
      {status && <Box sx={{ mb: 4 }}>{status}</Box>}
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          const bodyFormData = new FormData();
          bodyFormData.set('reviewerName', values.reviewerName);
          bodyFormData.set('reviewerEmail', values.reviewerEmail);
          bodyFormData.set('reviewContent', values.reviewContent);

          axios.post('https://cms.chaloupkakteraexistuje.cz/wp-json/contact-form-7/v1/contact-forms/629/feedback', bodyFormData)
            .then((response) => {
              console.log(response);

              setStatus(response.data.message);

              if (response.data.status === 'mail_sent') {
                resetForm();
              }

              setSubmitting(false);
            })
            .catch((error) => {
              console.log(error);
              setSubmitting(false);
            });
        }}
      >
        <Form>
          <TextInput
            name="reviewerName"
            type="text"
            label="Jméno"
          />

          <TextInput
            name="reviewerEmail"
            type="email"
            label="E-mail"
          />

          <TextArea
            name="reviewContent"
            label="Text recenze"
            rows={4}
          />

          <CheckboxInput name="acceptedTerms">
          Souhlasím se zveřejněním jména a obsahu recenze.
          </CheckboxInput>

          <StyledButton type="submit">Odeslat recenzi</StyledButton>
        </Form>
      </Formik>
    </Box>
  );
};

export default ReviewForm;
