import * as Yup from 'yup';

export default Yup.object({
  reviewerName: Yup.string()
    .min(3, 'Minimálně 3 znaky.')
    .required('Sdělte nám prosím jméno, které se zobrazí u recenze.'),
  reviewerEmail: Yup.string()
    .email('Neplatná e-mailová adresa.')
    .required('Napište nám prosím e-mailovou adresu. Nebude zobrazena u recenze.'),
  reviewContent: Yup.string()
    .min(50, 'Nejméně 50 znaků.')
    .max(2000, 'Maximálně 2000 znaků.')
    .required('Napište prosím text recenze.'),
  acceptedTerms: Yup.boolean()
    .required('Toto pole potřeba zaškrtnout pro odeslání recenze.')
    .oneOf([true], 'Musíte souhlasit s podmínkami zveřejnění recenze.'),
});
