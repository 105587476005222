import React from 'react';
import { useField } from 'formik';

import Label from './Label';
import InputWrapper from './InputWrapper';
import StyledTextarea from './StyledTextarea';
import StyledError from './StyledError';

const TextArea = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <InputWrapper>
      <Label htmlFor={props.id || props.name}>{label}</Label>

      <StyledTextarea {...field} {...props} />
      {meta.touched && meta.error ? (
        <StyledError>{meta.error}</StyledError>
      ) : null}
    </InputWrapper>
  );
};

export default TextArea;
