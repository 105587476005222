import styled from 'styled-components';

const StyledInput = styled.input`
  width: 100%;
  padding: 0.5rem;
  border: 1px solid ${props => props.theme.colors.text};
  outline: none;
`;

export default StyledInput;
