import React from 'react';
import { useField } from 'formik';

import Checkbox from './Checkbox';
import Label from './Label';
import InputWrapper from './InputWrapper';
import StyledError from './StyledError';

const CheckboxInput = ({ children, ...props }) => {
  const [field, meta] = useField({ ...props, type: 'checkbox' });
  return (
    <InputWrapper>
      <Label className="checkbox" style={{ alignItems: 'center' }}>
        <Checkbox type="checkbox" style={{ marginRight: '0.5rem' }} {...field} {...props} />
        {children}
      </Label>
      {meta.touched && meta.error ? (
        <StyledError>{meta.error}</StyledError>
      ) : null}
    </InputWrapper>
  );
};

export default CheckboxInput;
