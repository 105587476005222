import styled from 'styled-components';
import check from '../images/check.svg';

const Checkbox = styled.input`
  width: 1.5rem;
  height: 1.5rem;
  background-color: #FFFFFF;
  background-image: url(${check});
  background-position: 50% 450%;
  background-repeat: no-repeat;
  background-size: 85% 80%;
  border: 1px solid ${props => props.theme.colors.text};
  cursor: pointer;
  outline: 0;
  transition: background-position 100ms ease-in-out;

  &:checked {
    background-position: 50% 50%;
  }
`;

export default Checkbox;
