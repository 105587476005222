import styled from 'styled-components';

const StyledButton = styled.button`
  padding: 0.75rem 1rem;
  background-color: #fff;
  border: 1px solid ${props => props.theme.colors.text};
  cursor: pointer;
  outline: none;
  text-decoration: none;
  transition: ${props => props.theme.transitions.default};
  white-space: nowrap;

  &:focus,
  &:hover {
    background-color: ${props => props.theme.colors.text};
    color: #fff;
  }
`;

export default StyledButton;
