import styled from 'styled-components';

const StyledTextarea = styled.textarea`
  width: 100%;
  padding: 0.5rem;
  border: 1px solid ${props => props.theme.colors.text};
  outline: none;
  resize: vertical;
  min-height: calc(1.75 * 1rem * 4);
`;

export default StyledTextarea;
