import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import { Flex } from 'rebass/styled-components';

import Layout from '../components/Layout';
import { Section } from '../components/Section';
import Container from '../components/Container';
import ReviewForm from '../components/ReviewForm';

const Content = styled.div`
  img {
    max-width: 100%;
  }
`;

export const RecenzePage = ({ title }) => (
  <Section>
    <Container>
      <h1>{title}</h1>
      <Content>
        <Flex
          sx={{
            maxWidth: '32rem',
            width: '100%',
          }}
        >
          <ReviewForm />
        </Flex>
      </Content>
    </Container>
  </Section>
);

RecenzePage.propTypes = {
  title: PropTypes.string.isRequired,
};

const Page = () => (
  <Layout>
    <Helmet title="Napsat recenzi" />
    <RecenzePage title="Napsat recenzi" />
  </Layout>
);

export default Page;
